import React from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import theme from "../theme";

const ScrollLayout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
      height: 'calc(100% - 64px)',
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }}>
      <Outlet />
    </Box>
  );
};

export default ScrollLayout;
