import React from 'react';
import { Card, CardContent, CardMedia, Typography, Chip, Stack, Rating, Box, CardActionArea } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Expert } from '../../../classes/expert';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ExpertCardProps {
  expert: Expert;
}

const ExpertCard: React.FC<ExpertCardProps> = ({ expert }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/community/experts/${expert.id}`);
  };

  return (
    <Card sx={{ maxWidth: 345, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardActionArea onClick={handleClick} sx={{ height: '100%' }}>
        {expert.imageUrl && (
          <CardMedia
            component="img"
            height="250"
            width="250"
            image={expert.imageUrl}
            alt={expert.name}
            sx={{ 
              objectFit: 'cover',
              aspectRatio: '1/1'
            }}
          />
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h6" component="div">
            {expert.name}
          </Typography>
          {expert.title && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {expert.title}
            </Typography>
          )}
          
          {expert.location && (
            <Stack direction="row" spacing={1} alignItems="center" mb={1}>
              <LocationOnIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {expert.location}
              </Typography>
            </Stack>
          )}

          {expert.rating !== undefined && (
            <Box mb={2}>
              <Rating value={expert.rating} readOnly precision={0.5} size="small" />
            </Box>
          )}

          {expert.projectsCount !== undefined && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t('experts.card.projects_count', { count: expert.projectsCount })}
            </Typography>
          )}

          {expert.specialties && expert.specialties.length > 0 && (
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1} sx={{ mt: 2 }}>
              {expert.specialties.map((specialty, index) => (
                <Chip
                  key={index}
                  label={specialty}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Stack>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ExpertCard;
