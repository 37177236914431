import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import ExpertCard from "../../../components/community/experts/ExpertCard";
import { Expert } from "../../../classes/expert";
import { useTranslation } from "react-i18next";
import Loading from "../../../common/components/Loading";
import { getText } from "../../../utils/language";

const GET_EXPERTS = gql`
  query GetExperts {
    experts {
      bio_en
      bio_it
      id
      image_url
      location
      name
      title_en
      title_it
      expert_specialties {
        specialty {
          name_en
          name_it
        }
      }
    }
  }
`;

const ExpertList: React.FC = () => {
  const [experts, setExperts] = React.useState<Expert[]>([]);
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_EXPERTS);

  React.useEffect(() => {
    if (data) {
      setExperts(data.experts.map((expert: any) => {
        return {
          bio: getText(i18n, expert.bio_en, expert.bio_it),
          id: expert.id,
          imageUrl: expert.image_url,
          location: expert.location,
          name: expert.name,
          title: getText(i18n, expert.title_en, expert.title_it),
          specialties: expert.expert_specialties.map((specialty: any) => specialty.specialty.name_en),
        };
      }))
    }
  }, [data]);

  if (loading) return <Loading open />;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('experts.title')}
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        {t('experts.subtitle')}
      </Typography>
      
      <Box sx={{ mt: 4 }}>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={3}
          sx={{ margin: 0 }}
        >
          {experts.map((expert) => (
            <div key={expert.id}>
              <ExpertCard expert={expert} />
            </div>
          ))}
        </Masonry>
      </Box>
    </Container>
  );
};

export default ExpertList;
