import React from 'react';
import {
  Box,
  Typography,
  Chip,
  Paper,
  Avatar,
  useMediaQuery,
  IconButton,
  Tooltip,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';
import CommentList from '../../../components/community/forum/CommentList';
import CommentForm from '../../../components/community/forum/CommentForm';
import Loading from '../../../common/components/Loading';
import { formatDateAgo } from '../../../common/utils/text';
import { ForumPost, ForumComment } from '../../../classes/forum';
import { stringAvatar } from '../../../common/utils/avatar';
import Markdown from '../../../components/utils/Markdown';
import PostButtons from '../../../components/community/forum/PostButtons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const PostDetails: React.FC = () => {
  const { postUUID } = useParams<{ postUUID: string }>();
  const [post, setPost] = React.useState<ForumPost>();
  const navigate = useNavigate();
  const { authenticatedUserEmail } = useUser();
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  const { data, loading, error } = useQuery(gql`
    query Post($id: bigint!, $limit: Int!, $offset: Int!) {
      forum_posts_by_pk(id: $id) {
        created_at
        current_status
        description
        email
        forum_post_comments_aggregate {
          aggregate {
            count
          }
        }
        forum_post_comments(
          order_by: { created_at: asc }
          limit: $limit
          offset: $offset
        ) {
          comment
          created_at
          email
          id
          username
        }
        id
        title
        username
      }
    }
  `, {
    variables: {
      id: postUUID,
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
    },
  });

  React.useEffect(() => {
    if (data?.forum_posts_by_pk) {
      const postData = data.forum_posts_by_pk;
      setPost({
        id: postData.id,
        username: postData.username,
        email: postData.email,
        createdAt: postData.created_at,
        title: postData.title,
        description: postData.description,
        currentStatus: postData.current_status,
        nComments: postData.forum_post_comments_aggregate.aggregate.count,
        comments: postData.forum_post_comments.map((comment: any): ForumComment => ({
          id: comment.id,
          comment: comment.comment,
          createdAt: comment.created_at,
          username: comment.username,
          email: comment.email,
        })),
      });
    }
  }, [data]);

  if (loading) {
    return <Loading open />;
  }

  if (!post) {
    return (
      <Box sx={{ p: 3, width: '100%', maxWidth: '1000px', mx: 'auto' }}>
        <Typography>Post not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: isMobile ? 1 : 3, pt: 3, pb: 3, width: '100%', maxWidth: '1000px', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Tooltip title={t('Back to Forum')}>
          <IconButton
            onClick={() => navigate('/community/forum')}
            sx={{ mr: 'auto' }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Paper sx={{ p: 3, pt: 1, mb: 3 }}>
        <Box sx={{ mb: 2 }}>
          {post && authenticatedUserEmail === post.email && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 0 }}>
              <PostButtons post={post} />
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Chip
              label={t(`forum.status.${post.currentStatus.toLowerCase()}`)}
              size="small"
              color={post.currentStatus === 'Open' ? 'primary' : 'success'}
            />
            <Typography variant="h5" component="h1">
              {post.title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Avatar {...stringAvatar(post.username, { width: 24, height: 24, fontSize: 12 })} />
              <Typography variant="caption" color="text.secondary">
                {post.username}
              </Typography>
              <Typography variant="caption" color="text.secondary">•</Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDateAgo(post.createdAt, t)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <CommentIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {t('forum.comment_count', { count: post.nComments ?? 0 })}
              </Typography>
            </Box>
          </Box>
          <Markdown>{post.description}</Markdown>
        </Box>
      </Paper>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('forum.section.comments')}
        </Typography>
      </Box>
      
      <Box sx={{ mt: 3 }}>
        <CommentList
          comments={post.comments ?? []}
          itemsPerPage={itemsPerPage}
          totalItems={post.nComments ?? 0}
          page={page}
          onPageChange={setPage}
        />
      </Box>

      <CommentForm post_id={post.id} />
    </Box>
  );
};

export default PostDetails;
